import FooterLink from '@root/joinroot.com/src/components/footer/footer-link';
import FooterLinksColumn from '@root/joinroot.com/src/components/footer/footer-links-column';
import FooterLinksContainer from '@root/joinroot.com/src/components/footer/footer-links-container';
import OsanoFooterLink from '@root/joinroot.com/src/components/footer/osano-footer-link';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import environment from '@root/core/src/utils/environment';

export default function FooterLinks({ isDarkMode }) {
  return (
    <FooterLinksContainer>
      <FooterLinksColumn>
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Car insurance FAQ'}
          url={'/help/'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Car insurance coverage'}
          url={'/coverage'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Renters insurance'}
          url={'/renters-insurance/'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Claims'}
          url={'/claims'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Test drive'}
          url={'/test-drive'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Referrals'}
          url={'/referrals'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Contact'}
          url={'/contact'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Investor relations'}
          url={'https://ir.joinroot.com/'}
        />
      </FooterLinksColumn>
      <FooterLinksColumn>
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Mobile app'}
          url={'/mobile-app'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Auto insurance by state'}
          url={'/availability'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Customer reviews'}
          url={'/customer-reviews'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Car insurance comparison'}
          url={'/car-insurance-comparison'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'How to manage your policy'}
          url={'/how-to-manage-your-policy'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Car insurance quote'}
          url={'/quote'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Car insurance discounts'}
          url={'/discounts'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Company'}
          url={`${environment.incSiteBaseUrl}/company/`}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Careers'}
          url={`${environment.incSiteBaseUrl}/careers/`}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Vulnerability Disclosure Policy'}
          url={'/security'}
        />
      </FooterLinksColumn>
      <FooterLinksColumn>
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Press'}
          url={`${environment.incSiteBaseUrl}/press/`}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Blog'}
          url={'/blog/'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Terms and conditions'}
          url={'/terms-and-conditions'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Privacy policy'}
          url={'/privacy-policy'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'App license agreement'}
          url={'/app-eula'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'UGC privacy notice'}
          url={'/ugc-privacy-notice'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Weather related events'}
          url={'/weather/'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Non-weather emergencies'}
          url={'/non-weather/'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Sitemap'}
          url={'/sitemap/'}
        />
        <FooterLink
          isDarkMode={isDarkMode}
          linkText={'Do not sell or share my personal information'}
          url={'/privacy/california/'}
        />
        {environment.osanoEnabled === 'true' && <OsanoFooterLink isDarkMode={isDarkMode} />}
      </FooterLinksColumn>
    </FooterLinksContainer>
  );
}

FooterLinks.propTypes = {
  isDarkMode: PropTypes.bool,
};
